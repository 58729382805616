import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import React from "react";
import Layout from "../../../components/AppComponents/Layout";
import AllSubmissionInstitutional from "../../../components/Apply/Institutional/AllSubmissionInstitutional";
import NotSubmittedSubmissionInstitutional from "../../../components/Apply/Institutional/NotSubmittedSubmissionInstitutional";
import SubmittedSubmissionInstitutional from "../../../components/Apply/Institutional/SubmittedSubmissionInstitutional";

export default function AdminApply() {
  return (
    <Layout pageName="Pengajuan">
      <Tabs>
        <TabList>
          <Tab>Semua</Tab>
          <Tab>Belum Terkirim</Tab>
          <Tab>Terkirim</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <AllSubmissionInstitutional />
          </TabPanel>
          <TabPanel>
            <NotSubmittedSubmissionInstitutional />
          </TabPanel>
          <TabPanel>
            <SubmittedSubmissionInstitutional />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  );
}
