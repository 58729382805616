import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import React from "react";
import Layout from "../../../components/AppComponents/Layout";
import AllSubmission from "../../../components/Apply/NonTeacher/AllSubmissionNonTeacher";
import NotSubmittedSubmission from "../../../components/Apply/NonTeacher/NotSubmittedSubmissionNonTeacher";
import SubmittedSubmission from "../../../components/Apply/NonTeacher/SubmittedSubmissionNonTeacher";

export default function AdminApplyNonTeacher() {
  return (
    <Layout pageName="Pengajuan Guru">
      <Tabs>
        <TabList>
          <Tab>Semua</Tab>
          <Tab>Belum Terkirim</Tab>
          <Tab>Terkirim</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <AllSubmission />
          </TabPanel>
          <TabPanel>
            <NotSubmittedSubmission />
          </TabPanel>
          <TabPanel>
            <SubmittedSubmission />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  );
}
