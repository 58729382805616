import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import React from "react";
import Layout from "../../../components/AppComponents/Layout";
import AllSubmissionStudent from "../../../components/Apply/Student/AllSubmissionStudent";
import NotSubmittedSubmissionStudent from "../../../components/Apply/Student/NotSubmittedSubmissionStudent";
import SubmittedSubmissionStudent from "../../../components/Apply/Student/SubmittedSubmissionStudent";

export default function AdminApply() {
  return (
    <Layout pageName="Pengajuan">
      <Tabs>
        <TabList>
          <Tab>Semua</Tab>
          <Tab>Belum Terkirim</Tab>
          <Tab>Terkirim</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <AllSubmissionStudent />
          </TabPanel>
          <TabPanel>
            <NotSubmittedSubmissionStudent />
          </TabPanel>
          <TabPanel>
            <SubmittedSubmissionStudent />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  );
}
