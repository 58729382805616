import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  SimpleGrid,
  Skeleton,
  Stack,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import Breadcrumb from "../../../components/AppComponents/Breadcrumb";
import React, { useEffect, useState } from "react";
import Layout from "../../../components/AppComponents/Layout";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchGetSubmission,
  fetchUpdateApply,
} from "../../../networks/libs/apply";
import ModalDeleteApply from "../../../components/Apply/ModalDeleteApply";
import ModalDetailImage from "../../../components/AppComponents/ModalDetailImage";

const ApplySchema = Yup.object().shape({
    identity_type: Yup.string().required("Required"),
    identity_number: Yup.string().required("Required"),
    applicant_name: Yup.string().required("Required"),
    applicant_address: Yup.string().required("Required"),
    applicant_phone: Yup.string().required("Required"),
    achievement_name: Yup.string().required("Required"),
    achievement_description: Yup.string()
        .required("Required")
        .max(100, "Maksimum 100 karakter"),
    achievement_level: Yup.string().required("Required"),
    achievement_ranking: Yup.string().required("Required"),
    organizer: Yup.string()
        .required("Required")
        .max(150, "Maksimum 150 karakter"),
    achievement_date: Yup.string().required("Required"),
  //   certificate_image: Yup.mixed().required("Required"),
  //   documentation_image: Yup.mixed().required("Required"),
  //   bank_account_image: Yup.mixed().required("Required"),
  //   identity_image: Yup.mixed().required("Required"),
  //   family_card_image: Yup.mixed().required("Required"),
});

export default function AdminApplyTeacherEdit() {
  let { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const breadcrumbItems = [
    {
      title: "Pengajuan",
      link: "/admin/apply-teacher",
    },
    {
      title: "Edit Pengajuan",
      link: `/admin/apply-teacher/edit${id}`,
    },
  ];

  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);
  const [submission, setSubmission] = useState([]);

  useEffect(() => {
    const getSubmission = async () => {
      try {
        setIsLoadingSubmission(true);
        const response = await fetchGetSubmission(
          localStorage.getItem("token"),
          id
        );

        if (response.data.content) {
          setIsLoadingSubmission(false);
          setSubmission(response.data.content);
        } else {
          setIsLoadingSubmission(false);
        }
      } catch (error) {
        setIsLoadingSubmission(false);
      }
    };

    getSubmission(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function apply(values) {
    setIsLoading(true);
    await fetchUpdateApply(
      {
          identity_type: values.identity_type,
          identity_number: values.identity_number,
          applicant_name: values.applicant_name,
          applicant_address: values.applicant_address,
          applicant_phone: values.applicant_phone,
          achievement_name: values.achievement_name,
          achievement_description: values.achievement_description,
          achievement_level: values.achievement_level,
          achievement_ranking: values.achievement_ranking,
          organizer: values.organizer,
          achievement_date: values.achievement_date,
          certificate_image: values.certificate_image,
          documentation_image: values.documentation_image,
          bank_account_image: values.bank_account_image,
          principal_statement_letter: values.principal_statement_letter,
          additional_info: values.additional_info,
      },
      localStorage.getItem("token"),
      id
    )
      .then((response) => {
        setIsLoading(false);
        toast({
          position: "top",
          title: `Berhasil mengubah pengajuan! `,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        navigate("/admin/apply-teacher");
      })
      .catch((error) => {
        setIsLoading(false);
        toast({
          position: "top",
          title: error.response.data.info,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  }
  return (
    <Layout pageName="Edit Pengajuan">
      <Stack direction="column" spacing="40px">
        <Breadcrumb items={breadcrumbItems} />
        <Skeleton isLoaded={!isLoadingSubmission}>
          <Box>
            <Formik
              initialValues={{
                  identity_type: submission?.identity_type,
                  identity_number: submission?.identity_number,
                  applicant_name: submission?.applicant_name,
                  applicant_birth_place: submission?.applicant_birth_place,
                  applicant_birth_date: submission?.applicant_birth_date,
                  applicant_address: submission?.applicant_address,
                  applicant_bank_number: submission?.applicant_bank_number,
                  applicant_phone: submission?.applicant_phone,
                  achievement_name: submission?.achievement_name,
                  achievement_description: submission?.achievement_description,
                  achievement_level: submission?.achievement_level,
                  achievement_ranking: submission?.achievement_ranking,
                  organizer: submission?.organizer,
                  achievement_date: submission?.achievement_date,
                  certificate_image: null,
                  documentation_image: null,
                  bank_account_image: null,
                  identity_image: null,
                  family_card_image: null,
                  appointment_decision_letter: null,
                  principal_statement_letter: null,
                  additional_info: submission?.additional_info,
              }}
              validationSchema={ApplySchema}
              onSubmit={(values) => {
                apply(values);
              }}
            >
                {({ errors, touched, setFieldValue }) => (
                    <Form>
                        <Stack direction="column" spacing="24px">
                            <SimpleGrid columns={{base: 1, md: 2}} gap="24px">
                                <Field name="identity_type">
                                    {({ field }) => (
                                        <FormControl
                                            id="role"
                                            isInvalid={
                                                errors.identity_type !== undefined &&
                                                touched.identity_type
                                            }
                                        >
                                            <FormLabel fontSize="xs" color="#595959">
                                                Tipe Identitas
                                            </FormLabel>

                                            <Select {...field} placeholder="Pilih Tipe Identitas">
                                                <option value="nip">NIP</option>
                                                <option value="nik">NIK</option>
                                            </Select>
                                            {errors.identity_type && touched.identity_type ? (
                                                <FormErrorMessage>
                                                    {errors.identity_type}
                                                </FormErrorMessage>
                                            ) : null}
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="identity_number">
                                    {({ field }) => (
                                        <FormControl
                                            id="identity_number"
                                            isInvalid={
                                                errors.identity_number !== undefined &&
                                                touched.identity_number
                                            }
                                        >
                                            <FormLabel fontSize="xs" color="#595959">
                                                Nomor Identitas
                                            </FormLabel>

                                            <InputGroup>
                                                <Input
                                                    placeholder="Nomor Identitas"
                                                    borderRadius="8px"
                                                    color="jsip.black"
                                                    _focus={{ border: "2px solid #00649A" }}
                                                    {...field}
                                                />
                                                <InputLeftElement
                                                    pointerEvents="none"
                                                    children={<Icon icon="bx:id-card" />}
                                                />
                                            </InputGroup>
                                            {errors.identity_number && touched.identity_number ? (
                                                <FormErrorMessage>
                                                    {errors.identity_number}
                                                </FormErrorMessage>
                                            ) : null}
                                        </FormControl>
                                    )}
                                </Field>
                            </SimpleGrid>

                            <SimpleGrid columns={{base: 1, md: 2}} gap="24px">
                                <Field name="applicant_name">
                                    {({ field }) => (
                                        <FormControl
                                            id="applicant_name"
                                            isInvalid={
                                                errors.applicant_name !== undefined &&
                                                touched.applicant_name
                                            }
                                        >
                                            <FormLabel fontSize="xs" color="#595959">
                                                Nama Lembaga
                                            </FormLabel>

                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    placeholder="Nama Pemohon"
                                                    borderRadius="8px"
                                                    color="jsip.black"
                                                    _focus={{ border: "2px solid #00649A" }}
                                                    {...field}
                                                />
                                                <InputLeftElement
                                                    pointerEvents="none"
                                                    children={<Icon icon="bx:user" />}
                                                />
                                            </InputGroup>
                                            {errors.applicant_name && touched.applicant_name ? (
                                                <FormErrorMessage>
                                                    {errors.applicant_name}
                                                </FormErrorMessage>
                                            ) : null}
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="applicant_phone">
                                    {({ field }) => (
                                        <FormControl
                                            id="applicant_name"
                                            isInvalid={
                                                errors.applicant_phone !== undefined &&
                                                touched.applicant_phone
                                            }
                                        >
                                            <FormLabel fontSize="xs" color="#595959">
                                                Nomor Lembaga (WA)
                                            </FormLabel>

                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    placeholder="Nomor Pemohon (WA)"
                                                    borderRadius="8px"
                                                    color="jsip.black"
                                                    _focus={{ border: "2px solid #00649A" }}
                                                    {...field}
                                                />
                                                <InputLeftElement
                                                    pointerEvents="none"
                                                    children={<Icon icon="bx:phone" />}
                                                />
                                            </InputGroup>
                                            {errors.applicant_phone && touched.applicant_phone ? (
                                                <FormErrorMessage>
                                                    {errors.applicant_phone}
                                                </FormErrorMessage>
                                            ) : null}
                                        </FormControl>
                                    )}
                                </Field>
                            </SimpleGrid>

                            <Field name="applicant_address">
                                {({ field }) => (
                                    <FormControl
                                        id="applicant_address"
                                        isInvalid={
                                            errors.applicant_address !== undefined &&
                                            touched.applicant_address
                                        }
                                    >
                                        <FormLabel fontSize="xs" color="#595959">
                                            Alamat Pemohon
                                        </FormLabel>

                                        <InputGroup>
                                            <Textarea
                                                type="text"
                                                placeholder="Alamat Pemohon"
                                                borderRadius="8px"
                                                color="jsip.black"
                                                _focus={{ border: "2px solid #00649A" }}
                                                {...field}
                                            ></Textarea>
                                        </InputGroup>
                                        {errors.applicant_address && touched.applicant_address ? (
                                            <FormErrorMessage>
                                                {errors.applicant_address}
                                            </FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="achievement_name">
                                {({ field }) => (
                                    <FormControl
                                        id="achievement_name"
                                        isInvalid={
                                            errors.achievement_name !== undefined &&
                                            touched.achievement_name
                                        }
                                    >
                                        <FormLabel fontSize="xs" color="#595959">
                                            Nama Kegiatan/Kompetisi
                                        </FormLabel>

                                        <InputGroup>
                                            <Input
                                                type="text"
                                                placeholder="Contoh: Lomba Cerdas Cermat"
                                                borderRadius="8px"
                                                color="jsip.black"
                                                _focus={{ border: "2px solid #00649A" }}
                                                {...field}
                                            />
                                            <InputLeftElement
                                                pointerEvents="none"
                                                children={<Icon icon="bx:certification" />}
                                            />
                                        </InputGroup>
                                        {errors.achievement_name &&
                                        touched.achievement_name ? (
                                            <FormErrorMessage>
                                                {errors.achievement_name}
                                            </FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="achievement_level">
                                {({ field }) => (
                                    <FormControl
                                        id="achievement_level"
                                        isInvalid={
                                            errors.achievement_level !== undefined &&
                                            touched.achievement_level
                                        }
                                    >
                                        <FormLabel fontSize="xs" color="#595959">
                                            Tingkat Kompetisi
                                        </FormLabel>

                                        <InputGroup>
                                            <Input
                                                type="text"
                                                placeholder="Contoh: Menengah Keatas, Provinsi, Kota, dll"
                                                borderRadius="8px"
                                                color="jsip.black"
                                                _focus={{ border: "2px solid #00649A" }}
                                                {...field}
                                            />
                                            <InputLeftElement
                                                pointerEvents="none"
                                                children={<Icon icon="bx:book-reader" />}
                                            />
                                        </InputGroup>
                                        {errors.achievement_level &&
                                        touched.achievement_level ? (
                                            <FormErrorMessage>
                                                {errors.achievement_level}
                                            </FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="achievement_ranking">
                                {({ field }) => (
                                    <FormControl
                                        id="achievement_ranking"
                                        isInvalid={
                                            errors.achievement_ranking !== undefined &&
                                            touched.achievement_ranking
                                        }
                                    >
                                        <FormLabel fontSize="xs" color="#595959">
                                            Peringkat
                                        </FormLabel>

                                        <InputGroup>
                                            <Input
                                                type="text"
                                                placeholder="Contoh: Juara Ke 1"
                                                borderRadius="8px"
                                                color="jsip.black"
                                                _focus={{ border: "2px solid #00649A" }}
                                                {...field}
                                            />
                                            <InputLeftElement
                                                pointerEvents="none"
                                                children={<Icon icon="bx:trophy" />}
                                            />
                                        </InputGroup>
                                        {errors.achievement_ranking &&
                                        touched.achievement_ranking ? (
                                            <FormErrorMessage>
                                                {errors.achievement_ranking}
                                            </FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="organizer">
                                {({ field }) => (
                                    <FormControl
                                        id="organizer"
                                        isInvalid={
                                            errors.organizer !== undefined && touched.organizer
                                        }
                                    >
                                        <FormLabel fontSize="xs" color="#595959">
                                            Penyelenggara
                                        </FormLabel>

                                        <Textarea
                                            placeholder="Penyelenggara"
                                            borderRadius="8px"
                                            color="jsip.black"
                                            _focus={{ border: "2px solid #00649A" }}
                                            {...field}
                                        />

                                        {errors.organizer && touched.organizer ? (
                                            <FormErrorMessage>
                                                {errors.organizer}
                                            </FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="achievement_date">
                                {({ field }) => (
                                    <FormControl
                                        id="achievement_date"
                                        isInvalid={
                                            errors.achievement_date !== undefined &&
                                            touched.achievement_date
                                        }
                                    >
                                        <FormLabel fontSize="xs" color="#595959">
                                            Tanggal Kompetisi
                                        </FormLabel>

                                        <InputGroup>
                                            <Input
                                                type="date"
                                                borderRadius="8px"
                                                color="jsip.black"
                                                _focus={{ border: "2px solid #00649A" }}
                                                {...field}
                                            />
                                            <InputLeftElement
                                                pointerEvents="none"
                                                children={<Icon icon="bx:calendar" />}
                                            />
                                        </InputGroup>
                                        {errors.achievement_date && touched.achievement_date ? (
                                            <FormErrorMessage>
                                                {errors.achievement_date}
                                            </FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="certificate_image">
                                {({ field }) => (
                                    <FormControl
                                        id="certificate_image"
                                        isInvalid={
                                            errors.certificate_image !== undefined &&
                                            touched.certificate_image
                                        }
                                    >
                                        <FormLabel fontSize="xs" color="#595959">
                                            Dokumen Sertifikat
                                        </FormLabel>

                                        <InputGroup>
                                            <Input
                                                type="file"
                                                placeholder="Dokumen Sertifikat"
                                                sx={{
                                                    "::file-selector-button": {
                                                        height: 10,
                                                        padding: 0,
                                                        mr: 4,
                                                        background: "none",
                                                        border: "none",
                                                        fontWeight: "bold",
                                                    },
                                                }}
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        "certificate_image",
                                                        event.currentTarget.files[0]
                                                    );
                                                }}
                                            />
                                            <InputLeftElement
                                                pointerEvents="none"
                                                children={<Icon icon="bx:file" />}
                                            />
                                        </InputGroup>

                                        <ModalDetailImage
                                            src={submission?.certificate_image}
                                            name="Dokumen Sertifikat"
                                        />

                                        {errors.certificate_image &&
                                        touched.certificate_image ? (
                                            <FormErrorMessage>
                                                {errors.certificate_image}
                                            </FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="documentation_image">
                                {({ field }) => (
                                    <FormControl
                                        id="documentation_image"
                                        isInvalid={
                                            errors.documentation_image !== undefined &&
                                            touched.documentation_image
                                        }
                                    >
                                        <FormLabel fontSize="xs" color="#595959">
                                            Foto Dokumentasi
                                        </FormLabel>

                                        <InputGroup>
                                            <Input
                                                type="file"
                                                placeholder="Foto Dokumentasi"
                                                sx={{
                                                    "::file-selector-button": {
                                                        height: 10,
                                                        padding: 0,
                                                        mr: 4,
                                                        background: "none",
                                                        border: "none",
                                                        fontWeight: "bold",
                                                    },
                                                }}
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        "documentation_image",
                                                        event.currentTarget.files[0]
                                                    );
                                                }}
                                            />
                                            <InputLeftElement
                                                pointerEvents="none"
                                                children={<Icon icon="bx:file" />}
                                            />
                                        </InputGroup>

                                        <ModalDetailImage
                                            src={submission?.documentation_image}
                                            name="Foto Dokumentasi"
                                        />

                                        {errors.documentation_image &&
                                        touched.documentation_image ? (
                                            <FormErrorMessage>
                                                {errors.documentation_image}
                                            </FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="bank_account_image">
                                {({ field }) => (
                                    <FormControl
                                        id="bank_account_image"
                                        isInvalid={
                                            errors.bank_account_image !== undefined &&
                                            touched.bank_account_image
                                        }
                                    >
                                        <FormLabel fontSize="xs" color="#595959">
                                            Foto Akun Bank
                                        </FormLabel>

                                        <InputGroup>
                                            <Input
                                                type="file"
                                                placeholder="Foto Akun Bank"
                                                sx={{
                                                    "::file-selector-button": {
                                                        height: 10,
                                                        padding: 0,
                                                        mr: 4,
                                                        background: "none",
                                                        border: "none",
                                                        fontWeight: "bold",
                                                    },
                                                }}
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        "bank_account_image",
                                                        event.currentTarget.files[0]
                                                    );
                                                }}
                                            />
                                            <InputLeftElement
                                                pointerEvents="none"
                                                children={<Icon icon="bx:file" />}
                                            />
                                        </InputGroup>

                                        <ModalDetailImage
                                            src={submission?.bank_account_image}
                                            name="Foto Akun Bank"
                                        />

                                        {errors.bank_account_image &&
                                        touched.bank_account_image ? (
                                            <FormErrorMessage>
                                                {errors.bank_account_image}
                                            </FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="principal_statement_letter">
                                {({ field }) => (
                                    <FormControl
                                        id="principal_statement_letter"
                                        isInvalid={
                                            errors.principal_statement_letter !== undefined &&
                                            touched.principal_statement_letter
                                        }
                                    >
                                        <FormLabel fontSize="xs" color="#595959">
                                            Surat Pernyataan Kepala Sekolah
                                        </FormLabel>

                                        <InputGroup>
                                            <Input
                                                type="file"
                                                placeholder="Surat Pernyataan Kepala Sekolah"
                                                sx={{
                                                    "::file-selector-button": {
                                                        height: 10,
                                                        padding: 0,
                                                        mr: 4,
                                                        background: "none",
                                                        border: "none",
                                                        fontWeight: "bold",
                                                    },
                                                }}
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        "principal_statement_letter",
                                                        event.currentTarget.files[0]
                                                    );
                                                }}
                                            />
                                            <InputLeftElement
                                                pointerEvents="none"
                                                children={<Icon icon="bx:file" />}
                                            />
                                        </InputGroup>

                                        <ModalDetailImage
                                            src={submission?.principal_statement_letter}
                                            name="Surat Pernyataan Kepala Sekolah"
                                        />

                                        {errors.principal_statement_letter &&
                                        touched.principal_statement_letter ? (
                                            <FormErrorMessage>
                                                {errors.principal_statement_letter}
                                            </FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="additional_info">
                                {({ field }) => (
                                    <FormControl
                                        id="additional_info"
                                        isInvalid={
                                            errors.additional_info !== undefined &&
                                            touched.additional_info
                                        }
                                    >
                                        <FormLabel fontSize="xs" color="#595959">
                                            Info Tambahan
                                        </FormLabel>

                                        <Textarea
                                            placeholder="Info Tambahan"
                                            borderRadius="8px"
                                            color="jsip.black"
                                            _focus={{ border: "2px solid #00649A" }}
                                            {...field}
                                        />

                                        {errors.additional_info && touched.additional_info ? (
                                            <FormErrorMessage>
                                                {errors.additional_info}
                                            </FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>

                            <Button
                                w="100%"
                                size="lg"
                                variant="jsip-primary"
                                type="submit"
                                isLoading={isLoading}
                                _hover={{}}
                            >
                                Buat Pengajuan Baru
                            </Button>
                        </Stack>
                    </Form>
                )}
            </Formik>
          </Box>
        </Skeleton>
      </Stack>
    </Layout>
  );
}
