import {
  Box,
  Container,
  Flex,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";

function Footer() {
  return (
    <>
      <Box w="100%" bgColor="jsip.primary">
        <Container maxW="container.xl" py="10px">
          <Stack
            direction={["column", "column", "row", "row"]}
            spacing="40px"
            alignItems="center"
          >
            <Stack
              direction="row"
              spacing="24px"
              alignItems={"center"}
            >
              <Image src="/jsip-logo-new.png" h="50px" />
              <Flex
                direction="row"
                alignItems={["center", "center", "start", "start"]}
              >
                <Text fontWeight="500" color="white">
                  Copyright © 2023 -
                </Text>
                <Text fontWeight="700" color="white" marginLeft={"5px"}>
                  J-Sistem Informasi Prestasi (J-SIP)
                </Text>
              </Flex>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default Footer;
