/* eslint-disable react-hooks/exhaustive-deps */

import Navbar from "../components/AppComponents/Navbar";
import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
    Box,
    Center,
    Container, Grid, GridItem,
    Stack, Tab, TabList, TabPanel, TabPanels, Tabs,
    Text,
} from "@chakra-ui/react";
import Footer from "../components/AppComponents/Footer";
import React from "react";
import { Link } from "react-router-dom";

export default function Faq() {
    return (
        <>
            <Box pb="10px" minH={"100vh"}>
                <Navbar />
                <Box bgColor="jsip.primary" mb="20px" pt="60px">
                    <Container maxW="container.md">
                        <Center py="60px" minH="30vh">
                            <Stack direction="column" spacing="5px" alignItems="center">
                                <Text
                                    color="white"
                                    fontSize={["30px", "30px", "40px", "40px"]}
                                    fontWeight="800"
                                    textAlign="center"
                                >
                                    Soal Sering Ditanya (FAQ)
                                </Text>
                                <Text
                                    color="white"
                                    fontSize="16px"
                                    fontWeight="400"
                                    textAlign="center"
                                >
                                    Melalui platform ini diharapkan sobat prestasi dapat melihat pertanyaan apa saja yang sering muncul terkait permasalahan kurasi sehingga dapat membantu menjawab kendala yang dihadapi saat sobat prestasi melakukan proses pendaftaran kurasi.
                                </Text>
                            </Stack>
                        </Center>
                    </Container>
                </Box>
                <Container maxW={{base: "container.sm", sm: "container.xl"}} my={'80px'}>
                    <Box>
                        <Stack direction="column" spacing="40px">
                            <Tabs variant='soft-rounded'>
                                <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)'}} gap={4}>
                                    <GridItem p={5} bgColor={'white'} boxShadow={'md'} borderRadius={10}>
                                        <Text
                                            fontSize={{base: "md", sm: "lg"}}
                                            color="jsip.grey800"
                                        >
                                            Tipe Pertanyaan
                                        </Text>
                                        <TabList display={'flex'} flexDirection={'column'} marginTop={15} h={{base: 'auto', sm: "container.sm"}}>
                                            <Tab fontSize={'14px'} fontWeight={'400'}>Pertanyaan umum seputar J-SIP</Tab>
                                            <Tab fontSize={'14px'} fontWeight={'400'}>Pertanyaan seputar pengajuan</Tab>
                                        </TabList>
                                    </GridItem>
                                    <GridItem colSpan={2}>
                                        <TabPanels>
                                            <TabPanel>
                                                <Accordion>
                                                    <AccordionItem>
                                                        <h2>
                                                            <AccordionButton>
                                                                <Box as='span' flex='1' textAlign='left' fontWeight={'700'} textColor={'jsip.grey800'}>
                                                                    Apa itu J-SIP?
                                                                </Box>
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={4}>
                                                            J-Sistem Informasi Prestasi (J-SIP) merupakan suatu
                                                            pangkalan data berupa platform aplikasi digital yang
                                                            mendorong percepatan pendataan prestasi serta meningkatkan
                                                            kualitas SDM atau putra/putri daerah Kabupaten Jember.
                                                            Sebagai salah satu inovasi pemberian reward dan pendataan
                                                            prestasi oleh Kabupaten Jember di lingkup pemerintahan
                                                            Kabupaten Jember.
                                                        </AccordionPanel>
                                                    </AccordionItem>

                                                    <AccordionItem>
                                                        <h2>
                                                            <AccordionButton>
                                                                <Box as='span' flex='1' textAlign='left' fontWeight={'700'} textColor={'jsip.grey800'}>
                                                                    Apa Latar belakang & dampak kedepan?
                                                                </Box>
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={4}>
                                                            Peserta didik diharapkan akan terus berkelanjutan menorehkan
                                                            prestasi terbaik bagi Kabupaten Jember dengan adanya
                                                            pemberian rewarding dalam insentif prestasi secara
                                                            terintegrasi tersebut. Sebagaimana pemilihan kategori
                                                            inovasi yang dipilih yakni penyedia akses yang inovatif dan
                                                            diharapkan memudahkan masyarakat khususnya peserta didik
                                                            dalam mengimplementasikan penggunaan aplikasi pada platform
                                                            digital yang saat berkembang saat ini. Pemanfaatan era
                                                            transformasi digital melalui penggunaan J - Sistem Informasi
                                                            Prestasi (J-SIP) juga mendorong masyarakat untuk aktif
                                                            memanfaatkan teknologi.
                                                            <br />
                                                            <br />
                                                            Masyarakat khususnya orang tua dapat mengetahui informasi
                                                            akurat dan pasti apabila putra/putrinya memiliki prestasi
                                                            gemilang di bidang Akademik maupun Non Akademik. Salah satu
                                                            bentuk dukungan pemerintah dan juga bentuk kolaborasi
                                                            bersama masyarakat untuk membangun dan merencanakan masa
                                                            depan gemilang bagi putra/putri yang berprestasi melalui
                                                            program insentif prestasi dan juga sarana pelatihan bidang
                                                            prestasi untuk meningkatkan skill pada bidang prestasinya
                                                            masing – masing.
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                </Accordion>
                                            </TabPanel>
                                            <TabPanel>
                                                <Accordion>
                                                    <AccordionItem>
                                                        <h2>
                                                            <AccordionButton>
                                                                <Box as='span' flex='1' textAlign='left' fontWeight={'700'} textColor={'jsip.grey800'}>
                                                                    Data apa yang di perlukan untuk pengajuan?
                                                                </Box>
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={4}>
                                                            Data yang di perlukan, Syarat - syarat dan Tutorial pengajuan bisa di lihat <Link to={'/tutorial#data'}><Text color={'jsip.primary'}>disini</Text></Link>
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                </Accordion>
                                            </TabPanel>
                                        </TabPanels>
                                    </GridItem>
                                </Grid>
                            </Tabs>
                        </Stack>
                    </Box>
                </Container>
            </Box>
            <Footer />
        </>
    );
}
