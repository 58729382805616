import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Image,
  ListItem,
  OrderedList,
  SimpleGrid,
  Skeleton,
  Spacer,
  Stack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Show,
  Button,
  InputGroup,
  Input,
  InputLeftElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BannerCarousel from "../components/AppComponents/BannerCarousel/view";
import Navbar from "../components/AppComponents/Navbar";
import { fetchGetLandingPage } from "../networks/libs/landing-page";
import { Icon } from "@iconify/react";
import ModalPreviewImage from "../components/AppComponents/ModalPreviewImage";
import Footer from "../components/AppComponents/Footer";
import { fetchStatistic } from "../networks/libs/school";
import {Link} from "react-router-dom";

export default function Home() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  // const banners = [
  //   {
  //     title: "",
  //     linkUrl:
  //       "https://www.jemberkab.go.id/wp-content/uploads/2022/09/ISIAN-WEB-PEMKAB-UTAMA.jpg",
  //     imageUrl:
  //       "https://www.jemberkab.go.id/wp-content/uploads/2022/09/ISIAN-WEB-PEMKAB-UTAMA.jpg",
  //   },
  //   {
  //     title: "",
  //     linkUrl:
  //       "https://www.jemberkab.go.id/wp-content/uploads/2022/09/ISIAN-WEB-PEMKAB-BERLIBUR.jpg",
  //     imageUrl:
  //       "https://www.jemberkab.go.id/wp-content/uploads/2022/09/ISIAN-WEB-PEMKAB-BERLIBUR.jpg",
  //   },
  // ];

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStatistic, setIsLoadingStatistic] = useState(false);
  const [banners, setbanners] = useState([]);
  const [regents, setRegents] = useState([]);
  const [galeries, setGaleries] = useState([]);
  const [statistics, setStatistics] = useState([]);

  const getAllbanners = async () => {
    try {
      setIsLoading(true);
      const response = await fetchGetLandingPage();

      if (response.data.content) {
        setIsLoading(false);
        setbanners(response.data.content.jumbotron);
        setRegents(response.data.content.regent);
        setGaleries(response.data.content.gallery);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getStatistics = async () => {
    try {
      setIsLoadingStatistic(true);
      const response = await fetchStatistic();

      if (response.data.content) {
        setIsLoadingStatistic(false);
        const statistic = response.data.content.map((stat) => stat.total);
        setStatistics(statistic);
      } else {
        setIsLoadingStatistic(false);
      }
    } catch (error) {
      setIsLoadingStatistic(false);
    }
  };

  useEffect(() => {
    getAllbanners();
    getStatistics();
  }, []);

  const steps = [
    "Input data dan upload berkas calon penerima reward prestasi oleh sekolah data melalui web J-SIP",
    "Operator sekolah menerima data – data siswa/I yang telah memenuhi syarat untuk diajukan dalam rewards prestasi J-SIP yang diajukan secara kolektif (setiap Lembaga sekolah/operator wajib memverifikasi berkas agar sesuai dengan syarat – syarat yang sudah ditentukan)",
    "Login ke aplikasi/web J-SIP",
    "Upload berkas sesuai dengan fitur/menu yang telah ada",
    "Proses penerimaan berkas oleh Dinas Pendidikan Kabupaten Jember melalui sistem",
    "Verifikasi berkas oleh tim verifikator Dinas Pendidikan Kabupaten Jember",
    "Setelah proses verifikasi selesai, operator Lembaga sekolah menerima informasi terkait berkas yang diterima/ditolak",
    "Berkas yang ditolak atau tidak sesuai dengan syarat – syarat pengajuan reward prestasi tidak sah dalam proses pengajuan dan dilakukan pengembalian berkas untuk dilaksanakan verifikasi ulang. Berkas yang tidak dapat diajukan karena syarat – syarat pengajuan yang belum terpenuhi tidak dapat menerima reward",
  ];

  const requiredData = [
    {
      title: "Foto KTP/KIA",
      icon: "bxs:id-card",
    },
    {
      title: "Foto Kartu Keluarga (KK)",
      icon: "bxs:book-content",
    },
    {
      title:
        "Scan sertifikat/piagam kejuaraan sesuai dengan syarat – syarat kejuaraan (File Pdf/Word/ Picture/ Winrar Max 2 Mb)",
      icon: "bxs:award",
    },
    {
      title:
        "Foto Dokumentasi Juara (1 Buah, Max Size File 2 Mb, foto diambil yang paling jelas/tidak blur)",
      icon: "bxs:bar-chart-alt-2",
    },
    {
      title: "Buku rekening bank sesuai tahun berjalan (siswa/ortu)",
      icon: "bxs:bank",
    },
    {
      title:
        "Khusus untuk lembaga, mengupload Surat pernyataan Kepala Sekolah tentang kebenaran data syarat reward prestasi siswa yang diusulkan (Surat Pertanggungjawaban Mutlak)",
      icon: "bxs:envelope",
    },
  ];

  const terms = [
    "Siswa/siswi yang sesuai data administrasi kependudukan lahir dan bertempat tinggal di Kabupaten Jember, dibuktikan oleh KTP atau KIA",
    "Lembaga sekolah jenjang pendidikan TK, SD, & SMP di lingkungan Pemerintah Kabupaten Jember",
    "Mempunyai prestasi bidang ilmu pengetahuan dan teknologi (IPTEK) atau prestasi bidang olahraga, atau prestasi bidang seni",
    "Belum pernah mendapatkan reward prestasi dari pemerintah Kabupaten Jember saat pengajuan dilakukan",
    "Sertifikat/piagam kejuaraan prestasi hanya mendapatkan 1 (satu) kali reward prestasi",
    "Memiliki sertifikat/piagam kejuaraan yang dimenangkan (maksimal 3 sertifikat dengan prestasi tertinggi). Kejuaraan yang dimaksud adalah",
  ];

  const termsTable = [
    {
      sports: "Juara I, II, III atau Finalis kejuaraan internasional; atau",
      art: "Juara I, II, III atau Finalis kejuaraan internasional; atau",
      iptek: "Juara I, II, III atau Finalis kejuaraan internasional; atau",
    },
    {
      sports: "Juara I, II, atau III kejuaraan resmi tingkat nasional; atau",
      art: "Juara I, II, III PORSENI/ FLS2N/Festival Seni/MTQ, Pesparawi, atau lomba keagamaan sejenis tingkat nasional; atau",
      iptek:
        "Juara atau finalis lomba tingkat internasional yang diselenggarakan oleh kompetisi lomba",
    },
    {
      sports:
        "Juara Juara I, II, atau III kejuaraan resmi tingkat provinsi; atau",
      art: "Juara I atau II PORSENI/Festival Seni atau kegiatan sejenis tingkat provinsi; atau",
      iptek:
        "Juara lomba I, II, atau III tingkat provinsi, dan/atau nasional, yang diselenggarakan oleh institusi yang kredibelkredible; atau",
    },
    {
      sports:
        "Peserta kejuaraan beregu harus membuktikan kehadiran dan keikutsertaan seluruh anggota Tim pada saat lomba.",
      art: "Peserta kejuaraan beregu harus membuktikan kehadiran dan keikutsertaan seluruh anggota Tim pada saat lomba.",
      iptek:
        "Peserta kejuaraan beregu harus membuktikan kehadiran dan keikutsertaan seluruh anggota Tim pada saat lomba.",
    },
  ];

  const options = {
    responsive: true,
    color: "black",
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Statistik Data Pencairan Dana Prestasi",
        color: "black",
      },
    },
  };

  const data = {
    labels: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    datasets: [
      {
        label: "Dana Cair",
        data: statistics,
        fill: false,
        borderColor: "#00649A",
        tension: 0.1,
      },
    ],
  };

  return (
    <div>
      <Navbar />
      <Container
        id="beranda"
        maxW="container.xl"
        p={[
          "40px 0px 0px 0px",
          "40px 0px 0px 0px",
          "80px 0px 0px 0px",
          "80px 0px 0px 0px",
        ]}
      >
        <Skeleton isLoaded={!isLoading}>
          <Box>
            {/*<BannerCarousel slides={banners} controls indicators />*/}
            <SimpleGrid
                columns={{ base: 1, sm: 2 }}
                spacing={10}
                p={5}
            >
              <Box w={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Box py={{ base: "20px", sm: "150px" }}>
                  <Text fontWeight="700" fontSize={{ base: "xl", sm: "4xl" }} color="jsip.grey800" marginTop={{ base: "15px", sm: "0px" }}>
                    J-Sistem Informasi Prestasi <br/>(J-SIP)
                  </Text>
                  <Text fontSize={{ base: "sm", sm: "lg" }} color="jsip.grey700" marginTop="15px">
                    Pangkalan data berupa platform aplikasi digital yang mendorong percepatan pendataan prestasi serta meningkatkan kualitas SDM atau putra/putri daerah Kabupaten Jember. Sebagai salah satu inovasi pemberian reward dan pendataan prestasi oleh Kabupaten Jember di lingkup pemerintahan Kabupaten Jember.
                  </Text>
                  <Flex marginTop="50px">
                    <Link to={'/login'}>
                      <Button
                        variant="jsip-primary"
                        size="lg"
                        marginRight={'5px'}
                      >
                        Masuk
                      </Button>
                    </Link>
                    <Link to={'/tutorial'}>
                      <Button
                          size="lg"
                          border={'1px'}
                          borderColor={'jsip.primary'}
                          bgColor={'white'}
                          color={'jsip.primary'}
                      >
                        Tutorial Pengajuan
                      </Button>
                    </Link>
                  </Flex>
                </Box>
              </Box>
              <Box w={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Image borderRadius={{ base: "sm", sm: "3xl" }} src="https://api-jsip.youngpreneur.online/image?path=gallery/8aad566e-2024-4648-a490-4eb692d0a79c.jpg" />
              </Box>
            </SimpleGrid>
          </Box>
        </Skeleton>
      </Container>

      <Stack
          direction="column"
          spacing="80px"
          alignItems="center"
          w="100%"
          mt="40px"
      >
        <Box bgColor={'jsip.grey100'} w="100%" py="40px">
          <Box>
            <Container
                maxW="container.md"
            >
              <Center>
                <Stack direction="column" spacing="5px" alignItems="center">
                  <Text
                      color="jsip.grey800"
                      fontSize={{ base: "xl", sm: "4xl" }}
                      fontWeight="800"
                      textAlign="center"
                  >
                    Tracking Pengajuan Prestasi
                  </Text>
                  <Text color="jsip.grey700" fontSize="18px" textAlign="center">
                    Pilih sekolah anda untuk memulai Tracking.
                  </Text>
                </Stack>
              </Center>
            </Container>
          </Box>

          <Container
              maxW="container.sm"
              marginTop={{base: "20px", sm: "40px"}}
          >
            <Box display={'flex'} alignItems={'center'}>
              <InputGroup>
                <Input
                    bgColor={'white'}
                    size="lg"
                    placeholder="Ketik nama sekolah anda"
                />

                <InputLeftElement
                    h="100%"
                    pointerEvents="none"
                    children={
                      <Box color="jsip.grey700">
                        <Icon icon="bxs:school" fontSize="20px" />
                      </Box>
                    }
                />
              </InputGroup>

              <Button
                  variant="jsip-primary"
                  size="lg"
                  marginLeft={{base: "10px", sm: "20px"}}
              >
                Lacak
              </Button>
            </Box>
          </Container>
        </Box>
      </Stack>

      <Stack
        direction="column"
        spacing="80px"
        alignItems="center"
        w="100%"
        mt="40px"
      >
        <Container maxW="container.lg" id="statistic">
          <Skeleton isLoaded={!isLoadingStatistic}>
            <Line options={options} data={data} />
          </Skeleton>
        </Container>

        <Box bgColor="jsip.grey100" w="100%" py="40px">
          <Container maxW="container.lg">
            <Stack direction="column" spacing="40px" alignItems="center">
              <Text
                fontSize={{base: "xl", sm: "4xl"}}
                color="jsip.grey800"
                fontWeight="700"
              >
                J-SIP Didukung Oleh
              </Text>
              <Stack
                direction={["column", "column", "row", "row"]}
                spacing="40px"
                alignItems="center"
              >
                <Box p="20px">
                  <Image src="/jsip-logo-new.png" h="100px" />
                </Box>
                <Box p="20px">
                  <Image src="/logo_dispendik_2022.png" h="100px" />
                </Box>
                <Box p="20px">
                  <Image src="/logo_kabupaten_jember.png" h="100px" />
                </Box>
              </Stack>
            </Stack>
          </Container>
        </Box>

        <Container maxW="container.lg">
          <Box>
            <Stack direction="column" spacing="40px" alignItems="center">
              <Text
                fontSize={{base: "xl", sm: "4xl"}}
                color="jsip.grey800"
                fontWeight="700"
              >
                Foto Kegiatan
              </Text>
              <Show above="md">
                <Box>
                  <BannerCarousel slides={galeries} controls indicators />
                </Box>
              </Show>

              <Show below="md">
                <Box
                  style={{
                    columnCount: galeries?.length % 2 === 0 ? 2 : 3,
                    columnGap: "10px",
                  }}
                >
                  {galeries?.map((gallery) => (
                    <Box
                      margin="0"
                      display="grid"
                      gridTemplateRows="1fr auto"
                      marginBottom="10px"
                      style={{ breakInside: "avoid" }}
                    >
                      <ModalPreviewImage imageUrl={gallery?.image_url} />
                    </Box>
                  ))}
                </Box>
              </Show>
            </Stack>
          </Box>
        </Container>

        <Container maxW={{base: "container.sm", sm: "container.lg"}}>
          <Box>
            <Stack direction="column" spacing="40px">
              <Text
                  fontSize={{base: "xl", sm: "4xl"}}
                  color="jsip.grey800"
                  fontWeight="700"
                  textAlign={'center'}
              >
                Soal Sering Ditanya (FAQ)
              </Text>
              <Text color="jsip.grey700" fontSize="18px" textAlign="center">
                Melalui platform ini diharapkan sobat prestasi dapat melihat pertanyaan apa saja yang sering muncul terkait permasalahan kurasi sehingga dapat membantu menjawab kendala yang dihadapi saat sobat prestasi melakukan proses pendaftaran kurasi.
              </Text>
              <Tabs variant='soft-rounded'>
                <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)'}} gap={4}>
                  <GridItem p={5} bgColor={'white'} boxShadow={'md'} borderRadius={10}>
                    <Text
                        fontSize={{base: "md", sm: "lg"}}
                        color="jsip.grey800"
                    >
                      Tipe Pertanyaan
                    </Text>
                      <TabList display={'flex'} flexDirection={'column'} marginTop={15} h={{base: 'auto', sm: "container.sm"}}>
                        <Tab fontSize={'14px'} fontWeight={'400'}>Pertanyaan umum seputar J-SIP</Tab>
                        <Tab fontSize={'14px'} fontWeight={'400'}>Pertanyaan seputar pengajuan</Tab>
                      </TabList>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <TabPanels>
                      <TabPanel>
                        <Accordion>
                          <AccordionItem>
                            <h2>
                              <AccordionButton>
                                <Box as='span' flex='1' textAlign='left'>
                                  Apa itu J-SIP?
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              J-Sistem Informasi Prestasi (J-SIP) merupakan suatu
                              pangkalan data berupa platform aplikasi digital yang
                              mendorong percepatan pendataan prestasi serta meningkatkan
                              kualitas SDM atau putra/putri daerah Kabupaten Jember.
                              Sebagai salah satu inovasi pemberian reward dan pendataan
                              prestasi oleh Kabupaten Jember di lingkup pemerintahan
                              Kabupaten Jember.
                            </AccordionPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <h2>
                              <AccordionButton>
                                <Box as='span' flex='1' textAlign='left'>
                                  Apa Latar belakang & dampak kedepan?
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              Peserta didik diharapkan akan terus berkelanjutan menorehkan
                              prestasi terbaik bagi Kabupaten Jember dengan adanya
                              pemberian rewarding dalam insentif prestasi secara
                              terintegrasi tersebut. Sebagaimana pemilihan kategori
                              inovasi yang dipilih yakni penyedia akses yang inovatif dan
                              diharapkan memudahkan masyarakat khususnya peserta didik
                              dalam mengimplementasikan penggunaan aplikasi pada platform
                              digital yang saat berkembang saat ini. Pemanfaatan era
                              transformasi digital melalui penggunaan J - Sistem Informasi
                              Prestasi (J-SIP) juga mendorong masyarakat untuk aktif
                              memanfaatkan teknologi.
                              <br />
                              <br />
                              Masyarakat khususnya orang tua dapat mengetahui informasi
                              akurat dan pasti apabila putra/putrinya memiliki prestasi
                              gemilang di bidang Akademik maupun Non Akademik. Salah satu
                              bentuk dukungan pemerintah dan juga bentuk kolaborasi
                              bersama masyarakat untuk membangun dan merencanakan masa
                              depan gemilang bagi putra/putri yang berprestasi melalui
                              program insentif prestasi dan juga sarana pelatihan bidang
                              prestasi untuk meningkatkan skill pada bidang prestasinya
                              masing – masing.
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </TabPanel>
                      <TabPanel>
                        <Accordion>
                          <AccordionItem>
                            <h2>
                              <AccordionButton>
                                <Box as='span' flex='1' textAlign='left'>
                                  Apa itu J-SIP?
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              J-Sistem Informasi Prestasi (J-SIP) merupakan suatu
                              pangkalan data berupa platform aplikasi digital yang
                              mendorong percepatan pendataan prestasi serta meningkatkan
                              kualitas SDM atau putra/putri daerah Kabupaten Jember.
                              Sebagai salah satu inovasi pemberian reward dan pendataan
                              prestasi oleh Kabupaten Jember di lingkup pemerintahan
                              Kabupaten Jember.
                            </AccordionPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <h2>
                              <AccordionButton>
                                <Box as='span' flex='1' textAlign='left'>
                                  Apa Latar belakang & dampak kedepan?
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              Peserta didik diharapkan akan terus berkelanjutan menorehkan
                              prestasi terbaik bagi Kabupaten Jember dengan adanya
                              pemberian rewarding dalam insentif prestasi secara
                              terintegrasi tersebut. Sebagaimana pemilihan kategori
                              inovasi yang dipilih yakni penyedia akses yang inovatif dan
                              diharapkan memudahkan masyarakat khususnya peserta didik
                              dalam mengimplementasikan penggunaan aplikasi pada platform
                              digital yang saat berkembang saat ini. Pemanfaatan era
                              transformasi digital melalui penggunaan J - Sistem Informasi
                              Prestasi (J-SIP) juga mendorong masyarakat untuk aktif
                              memanfaatkan teknologi.
                              <br />
                              <br />
                              Masyarakat khususnya orang tua dapat mengetahui informasi
                              akurat dan pasti apabila putra/putrinya memiliki prestasi
                              gemilang di bidang Akademik maupun Non Akademik. Salah satu
                              bentuk dukungan pemerintah dan juga bentuk kolaborasi
                              bersama masyarakat untuk membangun dan merencanakan masa
                              depan gemilang bagi putra/putri yang berprestasi melalui
                              program insentif prestasi dan juga sarana pelatihan bidang
                              prestasi untuk meningkatkan skill pada bidang prestasinya
                              masing – masing.
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </TabPanel>
                    </TabPanels>
                  </GridItem>
                </Grid>
              </Tabs>
            </Stack>
          </Box>
        </Container>

        <Footer />
      </Stack>
    </div>
  );
}
