import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import React from "react";
import Layout from "../../../components/AppComponents/Layout";
import AllSubmission from "../../../components/Apply/Teacher/AllSubmissionTeacher";
import NotSubmittedSubmission from "../../../components/Apply/Teacher/NotSubmittedSubmissionTeacher";
import SubmittedSubmission from "../../../components/Apply/Teacher/SubmittedSubmissionTeacher";

export default function AdminApplyTeacher() {
  return (
    <Layout pageName="Pengajuan Guru">
      <Tabs>
        <TabList>
          <Tab>Semua</Tab>
          <Tab>Belum Terkirim</Tab>
          <Tab>Terkirim</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <AllSubmission />
          </TabPanel>
          <TabPanel>
            <NotSubmittedSubmission />
          </TabPanel>
          <TabPanel>
            <SubmittedSubmission />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  );
}
