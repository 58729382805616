import './App.css';
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import Login from './pages/Login';
import Home from './pages/Home';
import Dashboard from './pages/super-admin/Dashboard';
import Profile from './pages/Profile';
import Account from './pages/super-admin/Account';
import AccountCreate from './pages/super-admin/AccountCreate';
import DashboardVerifier from './pages/verifier/Dashboard';
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminApplyTeacher from './pages/admin/teacher/AdminApplyTeacher';
import AdminTracking from './pages/admin/AdminTracking';
import AdminApplyTeacherCreate from './pages/admin/teacher/AdminApplyTeacherCreate';
import AdminApplyTeacherDetail from './pages/admin/teacher/AdminApplyTeacherDetail';
import AdminApplyTeacherEdit from './pages/admin/teacher/AdminApplyTeacherEdit';
import Goverment from './pages/super-admin/Goverment/Goverment';
import GovermentDetail from './pages/super-admin/Goverment/GovermentDetail';
import Banner from './pages/super-admin/Banner/Banner';
import BannerCreate from './pages/super-admin/Banner/BannerCreate';
import UpdatePassword from './pages/UpdatePassword';
import School from './pages/super-admin/School/School';
import SchoolCreate from './pages/super-admin/School/SchoolCreate';
import Gallery from './pages/super-admin/Gallery/Gallery';
import GalleryCreate from './pages/super-admin/Gallery/GalleryCreate';
import Tracking from './pages/Tracking';
import VerifierBatch from './pages/verifier/VerifierBatch';
import VerifierBatchDetail from './pages/verifier/VerifierBatchDetail';

import VerifierBatchStudent from './pages/verifier/VerifierBatchStudent';
import VerifierBatchDetailStudent from './pages/verifier/VerifierBatchDetailStudent';

import VerifierBatchTeacher from './pages/verifier/VerifierBatchTeacher';
import VerifierBatchDetailTeacher from './pages/verifier/VerifierBatchDetailTeacher';

import VerifierBatchNonTeacher from './pages/verifier/VerifierBatchNonTeacher';
import VerifierBatchDetailNonTeacher from './pages/verifier/VerifierBatchDetailNonTeacher';

import VerifierBatchInstitutional from './pages/verifier/VerifierBatchInstitutional';
import VerifierBatchDetailInstitutional from './pages/verifier/VerifierBatchDetailInstitutional';

import History from './pages/History';
import Tutorial from './pages/Tutorial';
import Terms from './pages/Terms';
import About from './pages/About';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import Metadata from './pages/super-admin/Metadata/Metadata';
import Notification from './pages/Notification';
import AdminTeacherBatchDetail from './pages/admin/teacher/AdminTeacherBatchDetail';
import AdminApplyNonTeacher from "./pages/admin/non-teacher/AdminApplyNonTeacher";
import AdminApplyNonTeacherCreate from "./pages/admin/non-teacher/AdminApplyNonTeacherCreate";
import AdminApplyNonTeacherDetail from "./pages/admin/non-teacher/AdminApplyNonTeacherDetail";
import AdminApplyNonTeacherEdit from "./pages/admin/non-teacher/AdminApplyNonTeacherEdit";
import AdminNonTeacherBatchDetail from "./pages/admin/non-teacher/AdminNonTeacherBatchDetail";
import AdminApplyInstitutional from "./pages/admin/institutional/AdminApplyInstitutional";
import AdminApplyInstitutionalCreate from "./pages/admin/institutional/AdminApplyInstitutionalCreate";
import AdminApplyInstitutionalDetail from "./pages/admin/institutional/AdminApplyInstitutionalDetail";
import AdminApplyInstitutionalEdit from "./pages/admin/institutional/AdminApplyInstitutionalEdit";
import AdminInstitutionalBatchDetail from "./pages/admin/institutional/AdminInstitutionalBatchDetail";
import AdminApplyStudent from "./pages/admin/student/AdminApplyStudent";
import AdminApplyStudentCreate from "./pages/admin/student/AdminApplyStudentCreate";
import AdminApplyStudentDetail from "./pages/admin/student/AdminApplyStudentDetail";
import AdminApplyStudentEdit from "./pages/admin/student/AdminApplyStudentEdit";
import AdminStudentBatchDetail from "./pages/admin/student/AdminStudentBatchDetail";
import Faq from "./pages/Faq";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/change-password/:token' element={<ChangePassword />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/update-password' element={<UpdatePassword />} />
          <Route path='/tracking' element={<Tracking />} />
          <Route path='/history' element={<History />} />
          <Route path='/tutorial' element={<Tutorial />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/about' element={<About />} />
          <Route path='/faq' element={<Faq />} />

          <Route path='/notification' element={<Notification />} />

          <Route path='/super-admin/dashboard' element={<Dashboard />} />
          <Route path='/super-admin/account' element={<Account />} />
          <Route path='/super-admin/account/create' element={<AccountCreate />} />
          <Route path='/super-admin/goverment' element={<Goverment />} />
          <Route path='/super-admin/goverment/:id' element={<GovermentDetail />} />
          <Route path='/super-admin/banner' element={<Banner />} />
          <Route path='/super-admin/banner/create' element={<BannerCreate />} />
          <Route path='/super-admin/gallery' element={<Gallery />} />
          <Route path='/super-admin/gallery/create' element={<GalleryCreate />} />
          <Route path='/super-admin/school' element={<School />} />
          <Route path='/super-admin/school/create' element={<SchoolCreate />} />
          <Route path='/super-admin/metadata' element={<Metadata />} />

          <Route path='/verifier/dashboard' element={<DashboardVerifier />} />
          <Route path='/verifier/batch' element={<VerifierBatch />} />
          <Route path='/verifier/batch/:id' element={<VerifierBatchDetail />} />
          <Route path='/verifier/batch-student' element={<VerifierBatchStudent />} />
          <Route path='/verifier/batch-student/:id' element={<VerifierBatchDetailStudent />} />
          <Route path='/verifier/batch-teacher' element={<VerifierBatchTeacher />} />
          <Route path='/verifier/batch-teacher/:id' element={<VerifierBatchDetailTeacher />} />
          <Route path='/verifier/batch-nonteacher' element={<VerifierBatchNonTeacher />} />
          <Route path='/verifier/batch-nonteacher/:id' element={<VerifierBatchDetailNonTeacher />} />
          <Route path='/verifier/batch-institutional' element={<VerifierBatchInstitutional />} />
          <Route path='/verifier/batch-institutional/:id' element={<VerifierBatchDetailInstitutional />} />

          <Route path='/admin/dashboard' element={<AdminDashboard />} />
          <Route path='/admin/apply-teacher' element={<AdminApplyTeacher />} />
          <Route path='/admin/apply-teacher/create' element={<AdminApplyTeacherCreate />} />
          <Route path='/admin/apply-teacher/:id' element={<AdminApplyTeacherDetail />} />
          <Route path='/admin/apply-teacher/edit/:id' element={<AdminApplyTeacherEdit />} />
          <Route path='/admin/apply-teacher/batch/:id' element={<AdminTeacherBatchDetail />} />
          <Route path='/admin/apply-non-teacher' element={<AdminApplyNonTeacher />} />
          <Route path='/admin/apply-non-teacher/create' element={<AdminApplyNonTeacherCreate />} />
          <Route path='/admin/apply-non-teacher/:id' element={<AdminApplyNonTeacherDetail />} />
          <Route path='/admin/apply-non-teacher/edit/:id' element={<AdminApplyNonTeacherEdit />} />
          <Route path='/admin/apply-non-teacher/batch/:id' element={<AdminNonTeacherBatchDetail />} />
          <Route path='/admin/apply-institutional' element={<AdminApplyInstitutional />} />
          <Route path='/admin/apply-institutional/create' element={<AdminApplyInstitutionalCreate />} />
          <Route path='/admin/apply-institutional/:id' element={<AdminApplyInstitutionalDetail />} />
          <Route path='/admin/apply-institutional/edit/:id' element={<AdminApplyInstitutionalEdit />} />
          <Route path='/admin/apply-institutional/batch/:id' element={<AdminInstitutionalBatchDetail />} />
          <Route path='/admin/apply-student' element={<AdminApplyStudent />} />
          <Route path='/admin/apply-student/create' element={<AdminApplyStudentCreate />} />
          <Route path='/admin/apply-student/:id' element={<AdminApplyStudentDetail />} />
          <Route path='/admin/apply-student/edit/:id' element={<AdminApplyStudentEdit />} />
          <Route path='/admin/apply-student/batch/:id' element={<AdminStudentBatchDetail />} />
          <Route path='/admin/tracking' element={<AdminTracking />} />

          {/* <Route index element={<Home />} />
          <Route path='about' element={<About />} /> */}

          {/* <Route path='products' element={<SharedProductLayout />}>
            <Route index element={<Products />} />
            <Route path=':productId' element={<SingleProduct />} />
          </Route> */}

          {/* <Route
            path='dashboard'
            element={
              <ProtectedRoute user={user}>
                <Dashboard user={user} />
              </ProtectedRoute>
            }
          />
          <Route path='*' element={<Error />} /> */}


        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
